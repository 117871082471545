import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy policy - Fleet Serverless function fastest" />
    <div className="relative mt-12 md:mt-24 px-6 lg:px-12">
      <div className="container">
        <div className="flex flex-col w-full lg:w-3/6 lg:pr-12">
          <p className="text-gray mb-2">LEGAL</p>
          <h1 className="text-3xl sm:text-4xl md:text-5xl leading-tight font-semibold">
            Privacy Policy
          </h1>
          <p className="text-gray mt-8 leading-relaxed">
            Effective date: 15 April 2020
          </p>
        </div>
        <div className="relative mt-16 text-lg">
          <h2 className="text-2xl font-medium">Welcome to Fleet!</h2>
          <p className="text-gray mt-4">
            Welcome to the web site (the "Site") of Fleet FN, Inc. ("Fleet FN",
            "we", "us" and/or "our"). This Site is operated by Fleet FN and has
            been created to provide information about our company and our cloud
            functions platform (together with the Site, the "Services") to our
            Service visitors and other individuals covered by this Privacy
            Policy, described below ("you", "your"). This Privacy Policy sets
            forth an outline of the information that Fleet FN gathers.
          </p>
          <p className="text-gray mt-4">
            When you access or use the Services, you are consenting to the
            collection, transfer, manipulation, storage, disclosure and other
            uses of your information, including any Personal Information, as set
            out in this Privacy Policy.
          </p>
          <h2 className="text-2xl font-medium mt-8">Types of Data Collected</h2>
          <h4 className="text-md font-medium mt-4">Personal Data</h4>
          <p className="text-gray mt-4">
            When using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Account Information") as a customer and allow you to
            access your accounts. Personally identifiable information may
            include, but is not limited to:
          </p>
          <ul className="text-gray mt-4 list-disc">
            <li className="ml-4 mt-2">Email address</li>
            <li className="ml-4 mt-2">Your name</li>
            <li className="ml-4 mt-2">Cookies</li>
          </ul>
          <p className="text-gray mt-4">
            We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials and other information that may be
            of interest to you. You may opt out of receiving any, or all, of
            these communications from us by following the unsubscribe link.
          </p>
          <h4 className="text-md font-medium mt-4">Payments</h4>
          <p className="text-gray mt-4">
            You do not need to provide your credit card information unless you
            wish to continue with a paid subscription to our services. When
            signing up for one of our paid services, you must provide billing
            information. we will collect your card information and billing
            address, although we do not store full credit card or personal
            account numbers; instead, we work with our preferred payment
            partner, Stripe, to handle all transactions.
          </p>
          <h4 className="text-md font-medium mt-4">Website Visitors</h4>
          <p className="text-gray mt-4">
            We will collect your name, email address, and other contact
            information. We ask for and—at your option—collect personal
            information from you when you submit opt-in forms on our Websites,
            including places where you can sign up for and agree to receive
            commercial communications from us. We also may ask you to submit
            personal information if you choose to use interactive features of
            the Websites, including participation in surveys, promotions,
            requesting customer support, or otherwise communicating with us. We
            will send such communications in accordance with applicable law.
          </p>
          <h4 className="text-md font-medium mt-4">
            Website Cookies and Tracking
          </h4>
          <p className="text-gray mt-4">
            Like any other website, Fleet FN uses 'cookies'. These cookies are
            used to store information including visitors' preferences, and the
            pages on the website that the visitor accessed or visited. The
            information is used to optimize the users' experience by customizing
            our web page content based on visitors' browser type and/or other
            information.
          </p>
          <h4 className="text-md font-medium mt-4">Log files</h4>
          <p className="text-gray mt-4">
            When you visit and interact with most websites, we gather certain
            information and store it in log files. This information may include
            but is not limited to Internet Protocol (IP) addresses, system
            configuration information, URLs of referring pages, and location and
            language preferences. We rotate logs frequently and only store log
            files where absolutely necessary; e.g. to perform an abuse
            investigation, to analyze a distributed denial of service attack,
            etc.
          </p>
          <h2 className="text-2xl font-medium mt-8">Use of collected data</h2>
          <p className="text-gray mt-4">
            Fleet FN only processes personal information in a manner that is
            compatible and relevant to the purpose for which it was collected or
            authorized. As a general rule, all data we collect (including
            personal information, where applicable) can be used to:
          </p>
          <ul className="text-gray mt-4 list-disc">
            <li className="ml-4">
              Provide, operate, maintain, improve, and promote the Website and
              Services;
            </li>
            <li className="ml-4">
              To notify you about changes to our Service;
            </li>
            <li className="ml-4">
              Enable you to access and use the Website and Services;
            </li>
            <li className="ml-4">
              To gather analysis or valuable information so that we can improve
              our Service;
            </li>
            <li className="ml-4">To bill your account;</li>
            <li className="ml-4">To monitor the usage of our Service;</li>
            <li className="ml-4">
              To detect, prevent and address technical issues;
            </li>
            <li className="ml-4">
              To fulfill any other purpose for which you provide it;
            </li>
            <li className="ml-4">To provide customer support;</li>
            <li className="ml-4">
              Send commercial communications, according to your communication
              preferences, how to provide information about products and
              services, resources, newsletters, offers and promotions;
            </li>
            <li className="ml-4">
              Monitor and analyze trends, usage and activities related to the
              Services and for marketing or advertising purposes;
            </li>
          </ul>
          <h2 className="text-2xl font-medium mt-8">Transfer of Data</h2>
          <p className="text-gray mt-4">
            Your information, including Personal Data, may be transferred to –
            and maintained on – computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
          <p className="text-gray mt-4">
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
          </p>
          <p className="text-gray mt-4">
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <h2 className="text-2xl font-medium mt-8">Security of Data</h2>
          <p className="text-gray mt-4">
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
          <h2 className="text-2xl font-medium mt-8">Links to Other Sites</h2>
          <p className="text-gray mt-4">
            This Privacy Policy applies only to the Services. The Services may
            contain links to other web sites not operated or controlled by Fleet
            FN (the "Third Party Sites"). The policies and procedures we
            described here do not apply to the Third Party Sites. The links from
            the Services do not imply that Fleet FN endorses or has reviewed the
            Third Party Sites. We suggest contacting those sites directly for
            information on their privacy policies.
          </p>
          <h2 className="text-2xl font-medium mt-8">
            Change of Ownership or Business Transition
          </h2>
          <p className="text-gray mt-4">
            In the event of a change of ownership or other business transition,
            such as a merger, acquisition or sale of our assets, your
            information may be transferred in accordance with applicable privacy
            laws.
          </p>
          <h2 className="text-2xl font-medium mt-8">Children's Privacy</h2>
          <p className="text-gray mt-4">
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>
          <p className="text-gray mt-4">
            Fleet FN does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </p>
          <h2 className="text-2xl font-medium mt-8">
            Changes to This Privacy Policy
          </h2>
          <p className="text-gray mt-4">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="text-gray mt-4">
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update
            “effective date” at the top of this Privacy Policy.
          </p>
          <p className="text-gray mt-4">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h2 className="text-2xl font-medium mt-8">Contact Us</h2>
          <p className="text-gray mt-4">
            If you have any questions about this Privacy Policy, please contact
            us:{' '}
            <a href="malito:hello@fleetfn.com" className="text-primary">
              hello@fleetfn.com
            </a>
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default PrivacyPage;
